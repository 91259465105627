/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
import React, { useState, useEffect, useCallback } from 'react';
import { getDynamicFormsMetadata } from '_shared/api/marketplaceApi';
import { filterByApplicationType } from '_shared/utils/application';
import { convertToISODate } from '_shared/utils/date';
import {
  DependencyRule,
  DependsOnCondition,
  DependsOnField,
  fieldDependencyTypes,
  FieldMetaDataPropType,
  FieldTypes,
  NumberformatType,
  TextformatType,
} from '../fieldValidation/types';
import { selectionFieldTypes, customfields } from '../utils/constants';
import useToastMessage from './useToastMessage';
import { useAppState } from 'store';

const useForm = (
  prefilledValues: { [key: string]: any },
  initialMetadata: Array<FieldMetaDataPropType>,
  callback: Function,
  validateField: Function,
  onChange?: Function
) => {
  const { state: appState } = useAppState();
  const { errorToastMessage } = useToastMessage();
  const errInitialValues: { [key: string]: any } = {};
  const [values, setValues] = useState<{ [key: string]: any }>(prefilledValues);
  const [metadata, setMetadata] = useState(initialMetadata);
  const [errors, setErrors] = useState(errInitialValues);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // A dictionary to store the child(key) and parent(value) field names.
  let childMetadataDependencyDict: any = {};

  useEffect(() => {
    // Only sets initialMetadata if not empty to avoid errors while trying to access this value.
    if (initialMetadata.length) {
      setErrors({});
      setMetadata(filterByApplicationType(initialMetadata));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMetadata]);

  useEffect(() => {
    const isError = Object.values(errors).filter((x) => x).length;

    if (!isError && isSubmitting) {
      callback(values);
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleSubmit = (event: any) => {
    if (event) event.preventDefault();

    setErrors(validateField(filterMetaData(metadata), values, appState.system?.currentUser?.email));
    setIsSubmitting(true);
  };

  const getSelectionKeyForCheckbox = (oldSelectionKey: any, isChecked: boolean, param: string) => {
    if (oldSelectionKey && !Array.isArray(oldSelectionKey)) {
      let newSelectionKey = oldSelectionKey.split(',');

      if (isChecked && newSelectionKey.includes(param)) return `${oldSelectionKey},`;

      if (isChecked && !newSelectionKey.includes(param)) return `${oldSelectionKey + param},`;

      if (!isChecked && newSelectionKey.includes(param)) {
        const splits = oldSelectionKey.split(',');

        newSelectionKey = '';

        splits.forEach((element: any) => {
          if (element !== param) newSelectionKey += `${element},`;
        });

        return newSelectionKey.slice(0, -1);
      }
    }
    return `${param},`;
  };

  /**
   * Provide the id and value of the field to manually update its value.
   * @param propId
   * @param value
   */
  const handleManualChange = (propId: string, value: any) => {
    setValues((oldValue) => {
      return {
        ...oldValue,
        [propId]: value,
      };
    });

    setMetadata((oldMetadata) => {
      return oldMetadata.map((element) => {
        if (element.id === propId) element.value = value;
        return element;
      });
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target) return;

    const { id, value, type, name } = event.target;

    let childIndex = -1;
    let selectionKey = ''; // Used for radio and checkbox controls.
    let userInputValue: any = value;
    const targetName = selectionFieldTypes.concat(customfields).includes(type)
      ? event.target?.getAttribute('data-parentid')!
      : name || event.currentTarget.name || id;

    // Find element index at top level.
    const elementIndex = metadata.findIndex((element) => {
      if (element.children?.length)
        childIndex = element.children.findIndex((x) => x.name === targetName);

      return childIndex !== -1 ? true : element.name === targetName;
    });

    const metadataClone = [...metadata];

    // Get selected checkbox/radio control and its value.
    if (event.currentTarget) {
      if (selectionFieldTypes.includes(event.currentTarget.type))
        selectionKey = event.currentTarget.name;

      userInputValue = selectionFieldTypes.includes(event.currentTarget.type)
        ? event.target?.checked
        : event.target?.value;
    }

    if (
      event.target?.getAttribute &&
      event.target?.getAttribute('data-customfield') === TextformatType.DATEPICKER
    ) {
      const date = event.target?.getAttribute('data-date');

      if (!date) return;

      userInputValue = convertToISODate(date);
    }

    if (
      event.target?.getAttribute &&
      event.target?.getAttribute('data-customfield') === TextformatType.AUTOCOMPLETE
    ) {
      try {
        const targetAttribute = event.target?.getAttribute('data-selecteditem');

        if (targetAttribute) userInputValue = JSON.parse(targetAttribute);
      } catch (error) {
        console.error(error);
      }
    }

    if (event?.currentTarget?.getAttribute('data-customfield') === TextformatType.SWITCH) {
      userInputValue = event.currentTarget.getAttribute('data-checked') === 'true' ? false : true;
    }

    if (
      event.target?.getAttribute &&
      event.target?.getAttribute('data-customfield') === TextformatType.MULTISELECT
    ) {
      const action = event.target?.getAttribute('data-action');

      let relevantMetaDataField = metadataClone[elementIndex];

      // If metadata has children find relevant child field, else find field in metadata.
      const finalMetadata =
        (relevantMetaDataField.hasOwnProperty('children') &&
          relevantMetaDataField?.children?.length &&
          relevantMetaDataField?.children[childIndex]) ||
        relevantMetaDataField;

      if (action === 'add') {
        userInputValue = Array.isArray(finalMetadata?.value)
          ? [...finalMetadata.value, userInputValue]
          : userInputValue;
      } else if (action === 'remove') {
        userInputValue = Array.isArray(finalMetadata?.value)
          ? [...finalMetadata.value.filter((x: any) => x !== userInputValue)]
          : userInputValue;
      }
    }

    if (
      event.target?.getAttribute &&
      event.target?.getAttribute('data-format') === NumberformatType.COMMARISE &&
      Number(userInputValue.replace(/,/gi, ''))
    ) {
      // Comma separated number format.
      const enteredValue = value.replace(/,/gi, '');
      userInputValue = Number(enteredValue).toLocaleString();
    }

    if (
      type === 'radio' &&
      metadataClone[elementIndex].valueAsString &&
      metadataClone[elementIndex].fieldType === FieldTypes.RADIO
    ) {
      userInputValue = event.target.value;
    }

    const selectionKeyForCheckboxInParent =
      metadataClone &&
      getSelectionKeyForCheckbox(
        metadataClone[elementIndex] && metadataClone[elementIndex].selectionKey,
        Boolean(userInputValue),
        selectionKey
      );

    if (childIndex === -1) {
      metadataClone[elementIndex] = {
        ...metadataClone[elementIndex],
        value: userInputValue,
        selectionKey: type === 'checkbox' ? selectionKeyForCheckboxInParent : selectionKey,
      };

      if (
        metadataClone[elementIndex].fieldType === FieldTypes.CHECKBOX &&
        metadataClone[elementIndex].type === 'single'
      ) {
        metadataClone[elementIndex].value = !metadataClone[elementIndex].value;
      }

      setValues((stateValues) => {
        let computedValue = userInputValue;

        if (!metadataClone[elementIndex]) return;

        if (
          type === 'checkbox' &&
          metadataClone[elementIndex].fieldType === FieldTypes.CHECKBOX &&
          metadataClone[elementIndex].boolean
        ) {
          computedValue = metadataClone[elementIndex].value;
        } else if (type === 'checkbox') {
          computedValue = selectionKeyForCheckboxInParent.slice(0, -1);
        }
        if (type === 'radio' && !metadataClone[elementIndex].valueAsString) {
          if (selectionKey && selectionKey !== 'true' && selectionKey !== 'false') {
            computedValue = selectionKey;
          } else computedValue = selectionKey === 'true';
        }

        const newValues = {
          ...stateValues,
          [targetName]: computedValue,
        };
        onChange?.(newValues);
        return newValues;
      });
    } else {
      // Find element index for children.
      const metadataChildClone = metadataClone[elementIndex].children;
      const selectionKeyForCheckboxInChild =
        metadataChildClone &&
        getSelectionKeyForCheckbox(
          metadataChildClone[childIndex] && metadataChildClone[childIndex].selectionKey,
          Boolean(userInputValue),
          selectionKey
        );

      if (metadataChildClone) {
        metadataChildClone[childIndex] = {
          ...metadataChildClone[childIndex],
          value: userInputValue,
          selectionKey: type === 'checkbox' ? selectionKeyForCheckboxInChild : selectionKey,
        };
      }

      metadataClone[elementIndex] = {
        ...metadataClone[elementIndex],
      };

      setValues((stateValues) => {
        let computedValue = userInputValue;

        if (type === 'radio' && !metadataClone[elementIndex].valueAsString) {
          computedValue = selectionKey === 'true';
        }

        const newValues = {
          ...stateValues,
          [targetName]: computedValue,
        };

        onChange?.(newValues);

        return newValues;
      });
    }

    if (Object.values(childMetadataDependencyDict).includes(targetName)) {
      return checkIfChildFieldMetadataShouldBeUpdated(
        targetName,
        value,
        metadataClone,
        elementIndex
      );
    }

    setMetadata(metadataClone);
  };

  /**
   * Checks if the current field has child selects whose metadata depends on it's value.
   * @param currentFieldId
   * @param currentFieldValue
   * @param metadataClone
   * @param elementIndex
   */
  const checkIfChildFieldMetadataShouldBeUpdated = async (
    currentFieldId: string,
    currentFieldValue: any,
    metadataClone: Array<FieldMetaDataPropType>,
    elementIndex: number
  ) => {
    for (childMetadataDependencyDict of Object.entries(childMetadataDependencyDict)) {
      const [childName, parentName] = childMetadataDependencyDict;
      if (parentName !== currentFieldId) continue;

      const childField: FieldMetaDataPropType | undefined = metadataClone.find(
        (field) => field.name === childName
      );

      if (childField && checkIfAllFieldDependenciesMet(childField, metadataClone[elementIndex])) {
        await fetchOptionsFromDependancy(childField, currentFieldValue);
      }
    }

    setMetadata(metadataClone);
  };

  const findField = (dependsOn: DependsOnField | string) => {
    return metadata.find((clonedField) => {
      const dependsOnName = typeof dependsOn === 'string' ? dependsOn : dependsOn?.name;
      return clonedField.name === dependsOnName;
    });
  };

  /**
   * Returns true or false based on whether or not the parent field meets the dependency condition
   * @param dependsOn
   * @param parentField
   */
  const _handleDependsOnSearch = (
    dependsOn: DependsOnField,
    parentField?: FieldMetaDataPropType
  ) => {
    const foundField = parentField?.name === dependsOn.name ? parentField : findField(dependsOn);
    if (!foundField) return false;

    if (Array.isArray(foundField.value)) {
      return foundField.value.includes(dependsOn.value);
    }

    if (Array.isArray(foundField.selectionKey)) {
      return foundField.selectionKey?.includes(dependsOn.value);
    }

    if (typeof dependsOn.value === 'function') {
      return dependsOn.value(foundField);
    }

    return foundField.value === dependsOn?.value
      ? foundField.value === dependsOn?.value
      : values?.[dependsOn.name]?.toString() === dependsOn?.value;
  };

  /**
   * Returns the updated field or undefined if the field should be removed.
   * If it is being disabled, then the field value is reset.
   * @param field
   * @param enabled
   * @param dependencyType
   */
  const handleDisableField = useCallback((field: FieldMetaDataPropType, enabled: boolean) => {
    if (!enabled) resetFieldValue(field);
    field.disabled = !enabled;
    return field;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Checks if dependsOn is an array of dependencies or a standalone one and handles it accordingly.
   * Returns the edited field if only disabled or passes all checks, otherwise it returns undefined
   * to be filtered out.
   * @param field
   * @param parentField
   */
  const checkIfAllFieldDependenciesMet = (
    field: FieldMetaDataPropType,
    parentField?: FieldMetaDataPropType
  ) => {
    if (!field.dependsOn) return field;

    if (Array.isArray(field.dependsOn)) {
      const matchedFields = field.dependsOn.filter((dependency) => {
        const valid = _handleDependsOnSearch(dependency, parentField);

        if (dependency.dependencyType === fieldDependencyTypes.DISABLE) {
          field = handleDisableField(field, valid);
          return true;
        }

        return valid;
      });

      if (
        (field.dependsOnCondition === DependsOnCondition.OR ||
          field.dependencyRule === DependencyRule.OR) &&
        matchedFields.length
      )
        return field;
      if (field.dependencyRule === DependencyRule.AND && matchedFields.length > 1) return field;
      if (matchedFields.length === field.dependsOn.length) return field;

      resetFieldValue(field);
      return {};
    }

    const valid = _handleDependsOnSearch(field.dependsOn, parentField);
    if (valid) return field;

    resetFieldValue(field);
    return {};
  };

  const checkIfFieldShouldBeDisabled = (field: FieldMetaDataPropType) => {
    if (!field.fieldToDisable) return field;

    const fieldToDisable = metadata.find(
      (clonedField) => clonedField.name === field.fieldToDisable
    );

    if (fieldToDisable && (field.value === 'true' || field.value === true)) {
      return handleDisableField(fieldToDisable, !field.value);
    }

    return field;
  };

  /**
   * Uses the entities specified in the childField metadata and calls the getDynamicFormsMetadata endpoint to update the field options.
   * We will need to update this if more waterfall selects are added in future.
   * @param childField
   * @param parentFieldValue
   */
  const fetchOptionsFromDependancy = async (
    childField: FieldMetaDataPropType,
    parentFieldValue: any
  ) => {
    const entities = childField?.metadata?.optionsType;

    if (!parentFieldValue || !entities) {
      return errorToastMessage('Sorry, something went wrong...');
    }

    try {
      const { data } = await getDynamicFormsMetadata([entities], parentFieldValue);
      const options = data[entities];
      childField.options = options || [];
      childField.value = '';
      childField.hidden = !options.length;
    } catch (error) {
      errorToastMessage('Sorry, something went wrong...');
    }
  };

  const resetFieldValue = (field: FieldMetaDataPropType) => {
    if (field.name && values[field.name]) {
      setValues((oldValue) => {
        const newValues = { ...oldValue };
        if (field.name) delete newValues[field.name];
        return newValues;
      });
    }

    if (Array.isArray(field.value)) {
      field.value = [];
    } else {
      field.value = '';
    }

    if (field.selectionKey) {
      field.selectionKey = '';
    }
  };

  /**
   * Filters fields when dependsOn is set. When dependsOn is not met, reset the field value.
   * @param metaData
   * @returns
   */
  const filterMetaData = (metaData: Array<FieldMetaDataPropType>) => {
    return metaData
      .map((field) => {
        // This is used for waterfall selects who's metadata(options) depends on a parent field value.
        const requiredParentName = field?.metadata?.requiredParentField;
        if (requiredParentName) childMetadataDependencyDict[`${field.name}`] = requiredParentName;

        checkIfFieldShouldBeDisabled(field);
        return checkIfAllFieldDependenciesMet(field);
      })
      .filter((field) => Object.keys(field).length && !field.hidden);
  };

  return {
    handleChange,
    handleSubmit,
    handleManualChange,
    metadata: filterMetaData(metadata),
    errors,
    values,
  };
};

export default useForm;
