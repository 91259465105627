import { DependsOnField, FieldTypes, ValidationTypes } from '_shared/fieldValidation/types';
import { currencyToNumber } from './currency';
import { formatCurrency, parseDatePicker, parseMultiSelect } from 'pages/products/store/utils';

type TFieldValue = {
  [key: number]: {
    value: string | number | boolean | null | Date;
  };
};

type TFieldOption = {
  label: string;
  value: string | number | boolean;
  fullName?: string;
  name?: string;
  tooltip?: string;
};

export type TField = {
  formId: string;
  fieldId: string;
  sectionId: string;
  name: string;
  alternativeLabel?: string; // Alternative labels are used to facilitate differences needed between MP and BP
  label: string;
  dataType: string;
  type: string;
  rules: any;
  errorMessages: any;
  icon?: string;
  iconAlignment?: string;
  dependencies: any;
  displayOrder: number;
  tooltip?: string;
  dateCreated: string;
  createdBy: string;
  jsonTemp: string;
  value: any;
  options?: any;
  placeholder?: string;
  fieldToDisable?: string;
  required?: boolean;
  dependencyRule?: string;
  metadata?: any;
};

export type TOption = {
  id?: string;
  label: string;
  value: string | number | boolean | Date;
  name?: string;
};

export type TOptions = TOption[];

const componentMap: any = {
  'text.string': FieldTypes.TEXT,
  'text.integer': FieldTypes.TEXT,
  'text.currency': FieldTypes.CURRENCY,
  'currency.integer': FieldTypes.CURRENCY,
  'textarea.string': FieldTypes.TEXTAREA,
  'select.string': FieldTypes.SELECT,
  'select.array': FieldTypes.MULTISELECT,
  'select.integer': FieldTypes.SELECT,
  'radio.bool': FieldTypes.RADIO,
  'radio.string': FieldTypes.RADIO,
  'checkbox.bool': FieldTypes.CHECKBOX,
  'checkbox.array': FieldTypes.CHECKBOX,
  'date.date': FieldTypes.DATEPICKER,
  'percentage.integer': FieldTypes.PERCENTAGE,
  'range.integer': FieldTypes.CURRENCY,
};

const validationTypeMap: { [key: string]: ValidationTypes | undefined } = {
  'text.string': ValidationTypes.TEXT,
  'text.integer': ValidationTypes.NUMERIC,
  'currency.integer': ValidationTypes.CURRENCY,
  'textarea.string': ValidationTypes.TEXT,
  'select.string': ValidationTypes.SELECT,
  'select.array': ValidationTypes.MULTISELECT,
  'select.integer': ValidationTypes.SELECT,
  'radio.bool': ValidationTypes.RADIO,
  'radio.string': ValidationTypes.RADIO,
  'checkbox.bool': ValidationTypes.CHECKBOX,
  'checkbox.array': ValidationTypes.CHECKBOX,
  'date.date': ValidationTypes.DATE,
};

const optionsMapper = (
  type: FieldTypes,
  fieldName: string,
  options: TFieldOption[],
  dataType?: string
) => {
  if (type === FieldTypes.CHECKBOX && dataType === 'bool') {
    return [
      {
        name: fieldName,
        value: 'false',
        label: '',
        parentId: fieldName,
      },
    ];
  }

  return options?.map((option: TOption) => ({
    value: type === FieldTypes.RADIO ? option.value.toString() : option.value,
    label: option.label,
    name: type === FieldTypes.RADIO ? option.value.toString() : option.value,
    parentId: fieldName,
  }));
};

export const findValueInMetadata = (metadataArray: Array<TOption>, name: string | number) => {
  const { label } =
    metadataArray.find(({ label }: { label: string | number }) => {
      return (
        `${label}`.toLowerCase().replace(/\s+/g, '').replace(/[-]/g, '').replace(/\//gi, '') ===
        `${name}`.toLowerCase()
      );
    }) || {};
  return label || name;
};

const getValue = (type: FieldTypes, value: any, dataType: string) => {
  switch (type) {
    case FieldTypes.MULTISELECT:
      if (!value) {
        return [];
      } else {
        return typeof value === 'string' ? parseMultiSelect(value) : value;
      }
    case FieldTypes.DATEPICKER:
      return typeof value === 'string' ? parseDatePicker(value) : null;
    case FieldTypes.CURRENCY:
      return value ? formatCurrency(value) : '';
    case FieldTypes.CHECKBOX:
      return dataType === 'array' && value ? JSON.parse(value) : value || '';
    default:
      return value || '';
  }
};

const getFieldType = (fieldType: string, fieldDataType: string, icon?: string) => {
  if (icon && icon === 'currency') {
    return componentMap['currency.integer'];
  }
  return componentMap[`${fieldType}.${fieldDataType}`];
};

const dependsOnMapper = (dependsOn?: DependsOnField[]) => {
  return dependsOn
    ? dependsOn
        .flatMap((dependency: DependsOnField) =>
          Array.isArray(dependency) ? dependency : [dependency]
        )
        .map((dependency: any) => ({
          value: dependency.value?.toString(),
          name: dependency.field,
        }))
    : [];
};

export const fieldMapper = (field: TField) => {
  const fieldType = getFieldType(field.type, field.dataType, field.icon);
  const validationType = validationTypeMap[`${field.type}.${field.dataType}`] || null;

  if (fieldType === undefined) {
    return {};
  }

  let selectionKey = getValue(fieldType, field.value, field.dataType);

  if (fieldType === FieldTypes.CHECKBOX && selectionKey === 'true') {
    selectionKey = field.name;
  } else if (fieldType === FieldTypes.CHECKBOX && selectionKey === 'false') {
    selectionKey = undefined;
  }

  return {
    id: field.fieldId,
    name: field.name,
    label: field.alternativeLabel ?? field.label,
    type: field.type,
    dataType: field.dataType,
    fieldType: fieldType,
    value: getValue(fieldType, field.value, field.dataType),
    placeholder: field?.placeholder || null,
    selectionKey: [FieldTypes.CHECKBOX, FieldTypes.RADIO].includes(fieldType)
      ? selectionKey
      : undefined,
    validationtype: validationType,
    options: optionsMapper(fieldType, field.name, field?.options || [], field?.dataType),
    dependsOn: dependsOnMapper(field.dependencies),
    required: field?.fieldToDisable ? false : field?.rules?.required,
    fieldToDisable: field?.fieldToDisable,
    dependencyRule: field?.dependencyRule,
    metadata: field?.metadata,
  };
};

export const generateFundingFields = (form: any) => {
  return form?.formSections
    ?.flatMap((formSection: any) => formSection.formFields)
    .map((field: TField) => fieldMapper(field))
    .filter((field: TField) => field.type !== undefined && field.dataType !== undefined);
};

export const generateFundingFieldValues = (form: any) => {
  const fields: { [index: string]: any } = {};
  form?.formSections
    ?.flatMap((formSection: any) => formSection.formFields)
    .forEach((field: any) => {
      fields[field.name] = {
        value: field?.value || null,
        formFieldId: field.fieldId,
        dataType: field?.dataType?.toLowerCase(),
      };
    });

  return fields;
};

export const convertSaveValueBasedOnType: { [index: string]: any } = {
  string: (value: string) => {
    return String(value);
  },
  array: (value: any) => {
    if (!value) return '';
    try {
      return JSON.stringify(value);
    } catch (error) {
      console.error(error);
      return '';
    }
  },
  decimal: (value: string) => {
    if (!value) return;

    return String(currencyToNumber(value));
  },
  integer: (value: string) => {
    if (!value) return;

    if (typeof value === 'string' && value.indexOf(',') > -1) {
      return String(currencyToNumber(value));
    }

    return String(parseInt(value));
  },
  bool: (value: string) => {
    if (!value || value === 'false') return 'false';

    return String(Boolean(value));
  },
  date: (value: string | number) => {
    if (!value) return;

    return new Date(value).toISOString();
  },
};

export const formatGoalsFormsValues = (value: string, type: string) => {
  const convertFunction = convertSaveValueBasedOnType[type];
  if (convertFunction) {
    return convertFunction(value);
  }

  return value;
};
